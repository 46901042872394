import { Dispatch, SetStateAction, useEffect, useState } from "react";
import FormFieldValidation from "../../enums/FormFieldValidation";
import ICreateUser from "../../interfaces/ICreateUser";
import clientService from "../../services/ClientService";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import Dropdown from "./fields/Dropdown";
import TextField from "../../helpdesk/newTicket/fields/TextField";
import Status from "../../enums/Status";
import { useTranslation } from "react-i18next";
import accountService from "../../services/AccountService";

export default function NewUserForm() {
    const { t } = useTranslation();
    
    const [username, setUsername] = useState<string>("");
    const [isUsernameValid, setIsUsernameValid] = useState<FormFieldValidation>(FormFieldValidation.NOT_VALIDATED);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [clientId, setClientId] = useState<number | undefined>(undefined);
    const [isAdministrator, setIsAdministrator] = useState<boolean>(false);
    const [clients, setClients] = useState<{ id: number; name: string }[]>([]);
    const [status, setStatus] = useState<Status>(Status.NEUTRAL);

    const isFormDisabled = [Status.LOADING, Status.LOAD_ERROR, Status.SAVING, Status.SAVE_SUCCESS].includes(status);

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const clientsData = await getClients();
                setClients(clientsData);
            } catch (error) {
                console.error("Failed to fetch clients", error);
                setStatus(Status.LOAD_ERROR);
            }
        };

        fetchClients();
    }, []);

    const onSubmit = () => {
        const validateEmail = (email: string) => {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(email);
        };

        if (!validateEmail(username)) {
            setIsUsernameValid(FormFieldValidation.INVALID);
        } else {
            setIsUsernameValid(FormFieldValidation.VALID);
            const userData: ICreateUser = {
                username,
                firstName,
                lastName,
                clientId,
                isAdministrator
            };

            submitUser(setStatus, userData);
        }
    };

    return (
        <Form>
            <Row>
                <Col xs={12} lg={12}>
                    <TextField
                            type="text"
                            label={t("administration.form.fields.username")}
                            value={username}
                            onChange={setUsername}
                            validation={isUsernameValid}
                            validationMessage="Username is required" disabled={false}                />
                </Col>
            </Row>

            <Row>
                <Col xs={12} lg={6}>
                    <TextField
                            type="text"
                            label={t("administration.form.fields.firstName")}
                            value={firstName}
                            onChange={setFirstName} disabled={false} validation={FormFieldValidation.NOT_VALIDATED}                />
                </Col>
                <Col xs={12} lg={6}>
                    <TextField
                            type="text"
                            label={t("administration.form.fields.lastName")}
                            value={lastName}
                            onChange={setLastName} disabled={false} validation={FormFieldValidation.NOT_VALIDATED}                />
                </Col>
            </Row>

            <Row>
            <Col xs={12} lg={12}>
                <Form.Group controlId="chk-tc-accepted" className="mb-3">
                    <Form.Check
                        type="checkbox"
                        checked={isAdministrator}
                        label={t("administration.form.fields.isAdministrator")}
                        onChange={(e) => setIsAdministrator(e.target.checked)}
                    />
                </Form.Group>
                </Col>
                <Col xs={12} lg={12}>
                {!isAdministrator ? (
                        <Dropdown
                            label="Client"
                            options={clients.map(client => ({
                                value: client.id,
                                label: client.name,
                            }))}
                            value={clientId}
                            onChange={setClientId}
                            disabled={false} 
                            validation={FormFieldValidation.NOT_VALIDATED}                        />
                    ) : (
                        <Alert variant="info">
                            {t("administration.form.info")}
                        </Alert>
                    )}
                </Col>
            </Row>

            <Row>
                <Col xs={12} lg={{ span: 8, offset: 2 }} className="mt-5">
                    <div className="d-grid gap-2">
                        <Alert variant="info" show={status === Status.SAVING} className="mb-0">
                            {t("administration.form.saving")}
                        </Alert>
                        <Alert variant="success" show={status === Status.SAVE_SUCCESS} className="mb-0">
                            {t("administration.form.saved")}
                        </Alert>
                        <Alert variant="danger" show={status === Status.SAVE_ERROR} className="mb-0">
                            {t("administration.exceptions.createUser")}
                        </Alert>
                        {
                            status !== Status.SAVE_SUCCESS &&
                            <Button variant="primary" size="lg" disabled={isFormDisabled} onClick={onSubmit}>
                                {t("administration.form.submit")}
                            </Button>
                        }
                    </div>
                </Col>
            </Row>
        </Form>
    );
}

async function getClients() {
    return await clientService.getClients();
}

function submitUser (
    setStatus: Dispatch<SetStateAction<Status>>,
    userData: ICreateUser
) {
        setStatus(Status.SAVING);

        try {
            accountService.createUser(userData)
                .then((userId) => {
                    setStatus(Status.SAVE_SUCCESS);
                })
                .catch(() => setStatus(Status.SAVE_ERROR));
        } catch {
            setStatus(Status.SAVE_ERROR);
        }
}