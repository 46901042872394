import { Modal, Button, ListGroup, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import IClient from "../../../models/IClient";
import AssignClientsModalProps from "./AssignClientsModalProps"

export default function AssignClientsModal({
    show,
    onHide,
    onSave,
    currentUser,
    getClients,
}: AssignClientsModalProps) {
    const [clients, setClients] = useState<IClient[]>([]);
    const [selectedClients, setSelectedClients] = useState<number[]>([]);

    useEffect(() => {
        if (show) {
            fetchClients();
        }
    });

    const fetchClients = async () => {
        try {
            const clientList = await getClients();
            setClients(clientList);
        } catch (error) {
            console.error("Failed to fetch clients", error);
        }
    };

    const handleClientSelect = (clientId: number) => {
        setSelectedClients((prevSelected) =>
            prevSelected.includes(clientId)
                ? prevSelected.filter((id) => id !== clientId)
                : [...prevSelected, clientId]
        );
    };

    const handleSave = () => {
        onSave(selectedClients);
        setSelectedClients([]); // Reset after saving
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Assign Clients to {currentUser?.username}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {clients.map((client) => (
                        <ListGroup.Item key={client.id}>
                            <Form.Check
                                type="checkbox"
                                label={client.name}
                                checked={selectedClients.includes(client.id)}
                                onChange={() => handleClientSelect(client.id)}
                            />
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Assign Selected Clients
                </Button>
            </Modal.Footer>
        </Modal>
    );
}