import { useEffect, useState } from "react";
import accountService from "../../services/AccountService";
import IAccount from "../../models/IAccount";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import clientService from "../../services/ClientService";
import AssignClientsModal from "./modal/AssignClientsModal";

export default function Users() {
    const [users, setUsers] = useState<IAccount[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [currentUser, setCurrentUser] = useState<IAccount | null>(null);

    useEffect(() => {
        async function fetchUsers() {
            try {
                const response = await accountService.getAllAccounts();
                setUsers(response);
            } catch (error) {
                console.error("Failed to fetch users", error);
            }
        }

        fetchUsers();
    }, []);

    const handleAssignClients = (user: IAccount) => {
        setCurrentUser(user);
        setShowModal(true);
    };

    const getClients = async () => {
        return clientService.getClients();
    };

    const handleSaveClients = (selectedClients: number[]) => {
        console.log("Assigning clients", selectedClients, "to user", currentUser!.id);
        accountService.assignClients(currentUser!.id, selectedClients);
    };

    return (
        <Container>
            <h1 className="my-4">User List</h1>
            <Row>
                {users.map((user) => (
                    <Col xs={12} md={6} lg={4} key={user.id} className="mb-4">
                        <Card>
                            <Card.Body>
                                <Card.Title>{user.username}</Card.Title>
                                <Card.Text>
                                    <strong>{user.firstName} {user.lastName}<br /></strong>
                                </Card.Text>
                                <Button variant="primary" onClick={() => handleAssignClients(user)}>
                                    Assign Clients
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            <AssignClientsModal
                show={showModal}
                onHide={() => setShowModal(false)}
                onSave={handleSaveClients}
                currentUser={currentUser}
                getClients={getClients}
            />
        </Container>
    );
}